import { combineReducers } from '@reduxjs/toolkit';

import user from './userSlice';
import locations from './locationSlice';
import cars from './carSlice';
import notifications from './notificationSlice';

const rootReducer = combineReducers({
    user,
    locations,
    cars,
    notifications,
});

export default rootReducer;
