import api from './api';

export const getBlockedDates = async (carId, bookingFrom, bookingTo) => {
    try {
        const { data } = await api.get(
            `/api/v1/car/${carId}/unavailable-dates?bookingFrom=${bookingFrom}&bookingTo=${bookingTo}
            `);
        return data;
    } catch (error) {
        throw new Error('There was a Problem receiving blocked Dates');
    }
};

export const bookCar = async ({ car, pickupLocation, returnLocation, bookingFrom, bookingTo }) => {
    try {
        const { data } = await api.post('/api/v1/orders/', { car, pickupLocation, returnLocation, bookingFrom, bookingTo });
        return data;
    } catch (error) {
        throw new Error('There was a Problem booking your car');
    }
};

export const locationTermsOfService = async ({ locationId }) => {
    try {
        const { data } = await api.get(`/api/v1/locations/terms-of-service/${locationId}`);
        return data;
    } catch (error) {
        throw new Error('There was a problem retrieving the terms of service');
    }
};

export const getOwnBookings = async () => {
    try {
        const { data } = await api.get('/api/v1/orders/customer/own?noPagination');
        return data;
    } catch (error) {
        throw new Error('There was a Problem getting all your bookings');
    }
};

/**
 * @param {number} bookingId
 * @param {number} bookingFrom
 * @param {number} bookingTo
 * @returns {Promise<void>}
 */
export const updateCarBooking = async ({ bookingId, bookingFrom, bookingTo }) => {
    try {
        const { data } = await api.patch(`/api/v1/orders/${bookingId}`, { bookingFrom, bookingTo });
        return data;
    } catch (error) {
        throw new Error('There was a problem with updating your booking');
    }
};

export const finishCarBooking = async ({ bookingId, returnCarBookingObject }) => {
    try {
        const { data } = await api.patch(`/api/v1/orders/${bookingId}`, {
            ...returnCarBookingObject,
            returningCar: true,
        });
        return data;
    } catch (error) {
        throw new Error('There was a problem with updating your booking');
    }
};
