/* eslint no-param-reassign: 0 */
import { createSlice, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import api from '../api/api';
import {
    enqueueSnackbar as enqueueSnackbarAction
} from './notificationSlice';

const enqueueSnackbar = (...args) => enqueueSnackbarAction(...args);

const initialUser = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null;

const initialToken = localStorage.getItem('token')
    ? localStorage.getItem('token')
    : null;

const initialState = {
    user: initialUser,
    token: initialToken,
    loading: 'idle',
    error: null,
};

// Thunks
const loginUser = createAsyncThunk(
    'user/login',
    async ({ username, password }, { getState, rejectWithValue }) => {
        const { loading } = getState().user;
        if (loading !== 'pending') {
            return false;
        }
        try {
            const response = await api.post('/api/login', { username, password });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const logoutUser = createAsyncThunk(
    'user/logout',
    async (user, { getState, rejectWithValue }) => {
        const { loading, token } = getState().user;
        if (loading !== 'pending') {
            return false;
        }
        try {
            const response = await api.get('/api/logout');
            return response.data.success ? null : token;
        } catch (error) {
            return rejectWithValue('Logout Failed');
        }
    }
);

/*
We can use a state mutation inside createSlice,
because in here the Redux Toolkit will create immutables with immer.js
*/
const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        forceLogout: state => {
            state.token = null;
            localStorage.removeItem('token');
        },
    },
    extraReducers: {
        [loginUser.pending]: state => {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        [loginUser.fulfilled]: (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle';
                state.token = action.payload.token;
                localStorage.setItem('token', action.payload.token);
            }
        },
        [loginUser.rejected]: (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle';
                state.error = action.error;
            }
        },
        [logoutUser.pending]: state => {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        [logoutUser.fulfilled]: (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle';
                state.token = action.payload;
                localStorage.removeItem('token');
            }
        },
        [logoutUser.rejected]: (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle';
                state.error = action.error;
            }
        },
    },
});
export const { forceLogout } = slice.actions;
export default slice.reducer;

export const login = ({ username, password }) => dispatch => {
    dispatch(loginUser({ username, password }))
        .then(unwrapResult)
        .then(() => {
            dispatch(enqueueSnackbar({
                message: 'Erfolgreich eingeloggt',
                options: {
                    key: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
                    variant: 'success',
                },
            }));
        })
        .catch(() => {
            dispatch(enqueueSnackbar({
                message: 'Login nicht erfolgreich',
                options: {
                    key: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
                    variant: 'error',
                },
            }));
        });
};

export const logout = () => dispatch => {
    dispatch(logoutUser())
        .then(unwrapResult)
        .then(() => {
            dispatch(enqueueSnackbar({
                message: 'Erfolgreich ausgeloggt',
                options: {
                    key: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
                    variant: 'success',
                },
            }));
        })
        .catch(error => error);
};

export const forceUserLogout = () => dispatch => {
    dispatch(forceLogout());
};
