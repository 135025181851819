/* eslint no-param-reassign: 0 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    snacks: [],
};

const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        enqueueSnackbar(state, action) {
            state.snacks.push(action.payload);
        },
        closeSnackbar: {
            reducer: (state, action) => {
                state.snacks = state.snacks.map(snack => (
                    (action.payload.dismissAll || snack.key === action.payload.key)
                        ? { ...snack, dismissed: true }
                        : { ...snack }
                ));
            },
            prepare: key => (
                {
                    payload: {
                        dismissAll: !key,
                        key,
                    },
                }
            ),
        },
        removeSnackbar(state, action) {
            state.snacks = state.snacks.filter(snack => snack.key !== action.payload.key);
        },
    },
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = slice.actions;

export default slice.reducer;

export const getAllNotifications = state => state.notifications.snacks;
