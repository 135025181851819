import axios from 'axios';

const baseUrl = process.env.NODE_ENV === 'development' ? process.env.API_DOMAIN : 'https://develop.steli-pimcore.ecentral.ws';

const api = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
});

api.interceptors.request.use(
    config => {
        const axiosRequestConf = config;
        const token = localStorage.getItem('token');
        if (token !== null && token !== undefined) {
            axiosRequestConf.headers.Authorization = `Bearer ${token}`;
        }
        return axiosRequestConf;
    },
    error => Promise.reject(error)
);

export default api;
