import React from 'react';
import { useDispatch } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';

import AppBar from './AppBar';
import Notifier from './Notifier';
import api from '../api/api';
import { forceUserLogout } from './userSlice';

function App() {
    const dispatch = useDispatch();

    api.interceptors.response.use(
        response => response,
        async error => {
            if (error.response) {
                if (error.response.status === 401) {
                    dispatch(forceUserLogout());
                    return Promise.reject(error);
                }
            }
            return Promise.reject(error);
        }
    );

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar />
            <Notifier />
        </React.Fragment>
    );
}

export default App;
