import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

function CarinformationGridItem(props) {
    if (!props.value) {
        return <></>;
    }
    return (
        <Grid container item xs={12} spacing={3}>
            <Grid item xs={8}>
                <Typography component="span">
                    {props.label}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography component="span">
                    {props.value}
                </Typography>
            </Grid>
        </Grid>
    );
}

CarinformationGridItem.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default CarinformationGridItem;
