import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import * as momentJs from 'moment';
import Moment from 'react-moment';
import { extendMoment } from 'moment-range';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-dates/initialize';

import {
    enqueueSnackbar as enqueueSnackbarAction
} from '../../app/notificationSlice';
import Loader from '../../Components/Loader';

import CarBookingSuccess from './CarBookingSuccess';
import { bookCar, getOwnBookings } from '../../api/carBooking';
import DateTimeRangePicker from '../../Components/Booking/DateTimeRangePicker';

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 275,
        borderRadius: 0,
        height: 'calc(100vh - 104px)',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-end',
    },
    noHeightRoot: {
        minWidth: 275,
        borderRadius: 0,
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-end',
    },
    flexGrow: {
        flexGrow: 1,
    },
    fullWidth: {
        width: '100%',
    },
    customCtaButton: {
        padding: '16px 16px',
        fontSize: '1.375rem',
    },
}));

const moment = extendMoment(momentJs);

function CarBooking(props) {
    moment.locale('de');
    const history = useHistory();
    const dispatch = useDispatch();
    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [isCheckingAvailability/* setIsCheckingAvailability */] = useState(false);
    const [hasSuccessfulBooking, setHasSuccessfulBooking] = useState(false);
    const [successfulBooking, setSuccessfulBooking] = useState({});
    const [bookingDate, setBookingDate] = useState({
        startDate: null,
        endDate: null,
    });
    const [isBookable, setIsBookable] = useState(false);
    const [hasAgreedToTermsOfService, setHasAgreedToTermsOfService] = useState(false);
    const [isBookingPossible, setIsBookingPossible] = useState(true);

    useEffect(() => {
        getOwnBookings()
            .then((bookings) => {
                bookings.forEach(booking => {
                    if (booking.status !== 'abgeschlossen') {
                        setIsBookingPossible(false);
                    }
                });
                setIsLoading(false);
            });
    }, []);

    const bookSelectedDate = () => {
        setIsLoading(true);
        const bookingObj = {
            car: props.car.id,
            pickupLocation: props.car.location.id,
            returnLocation: props.car.location.id,
            bookingFrom: moment(bookingDate.startDate).unix(),
            bookingTo: moment(bookingDate.endDate).unix(),
        };
        bookCar(bookingObj)
            /** @param {{success: boolean, orderId: number}} response */
            .then(response => {
                setSuccessfulBooking({
                    id: response.orderId,
                    status: 'pending',
                    car: props.car,
                    pickupLocation: props.car.location.id,
                    returnLocation: props.car.location.id,
                    customer: 0,
                    bookingFrom: moment(bookingDate.startDate).unix(),
                    bookingTo: moment(bookingDate.endDate).unix(),
                    invoice: '',
                    attachments: [],
                });
                setHasSuccessfulBooking(true);
            })
            .catch(() => {
                enqueueSnackbar({
                    message: 'Es gab einen Error beim erstellen ihrer Buchung',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                });
            });
        setIsLoading(false);
    };

    if (isLoading) {
        return <Loader hasSecondaryMenu />;
    }

    if (!isBookingPossible) {
        return (
            <Card className={classes.noHeightRoot}>
                <CardContent className={classes.flexGrow}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <Typography
                            variant="h5"
                            component="h2"
                        >
                            Eine neue Buchung ist nicht möglich, solange noch Buchungen offen sind.
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                history.push({
                                    pathname: '/booking-list/',
                                    state: { appBarTitle: 'Buchungsübersicht' },
                                });
                            }}
                        >Zur Buchungsübersicht</Button>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    if (hasSuccessfulBooking) {
        return <CarBookingSuccess booking={successfulBooking} />;
    }

    return (
        <Fragment>
            <Card className={classes.root}>
                <CardContent className={classes.flexGrow}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={3}
                    >
                        <DateTimeRangePicker
                            carId={props.car.id}
                            bookingDateProp={bookingDate}
                            setBookingDateProp={setBookingDate}
                            classes={classes}
                            setIsBookable={setIsBookable}
                            setIsLoading={setIsLoading}
                        />
                    </Grid>
                    {isBookable && (
                        <Box mt={6}>
                            <Paper elevation={3}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}
                                    mt={3}
                                >
                                    <Grid item xs>
                                        <DriveEtaIcon
                                            color="primary"
                                            style={{ fontSize: 140 }}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="h2"
                                        >
                                            {props.car.manufacturer.name}{' '}
                                            {props.car.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            <Moment format="DD-MM-YYYY HH:00">
                                                {bookingDate.startDate}
                                            </Moment>{' '}
                                            bis{' '}
                                            <Moment format="DD-MM-YYYY HH:00">
                                                {bookingDate.endDate}
                                            </Moment>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box>
                    )}
                </CardContent>
                <CardContent>
                    <p>
                        {!isCheckingAvailability ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: props.car.location.termsOfService,
                                }}
                            />
                        ) : ''}
                    </p>
                    <Checkbox
                        checked={hasAgreedToTermsOfService}
                        color="default"
                        onChange={(event) => setHasAgreedToTermsOfService(event.target.checked)}
                    />
                </CardContent>
                {hasAgreedToTermsOfService ? (
                    <CardActions>
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            size="large"
                            className={classes.customCtaButton}
                            disabled={!isBookable}
                            onClick={bookSelectedDate}
                        >
                            {isCheckingAvailability && (
                                <CircularProgress color="inherit" size={30} />
                            )}
                            {!isCheckingAvailability ? 'Auto Buchen' : ''}
                        </Button>
                    </CardActions>
                ) : ''}
            </Card>
        </Fragment>
    );
}

CarBooking.propTypes = {
    car: PropTypes.shape({
        id: PropTypes.number,
        location: PropTypes.shape(
            {
                id: PropTypes.number,
                name: PropTypes.string,
                location: PropTypes.shape(
                    {
                        latitude: PropTypes.number,
                        longitude: PropTypes.number,
                    }
                ),
                termsOfService: PropTypes.string,
            }
        ),
        manufacturer: PropTypes.shape(
            {
                name: PropTypes.string,
                logo: PropTypes.string,
            }
        ),
        model: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        yearOfProduction: PropTypes.number,
        countryOfProduction: PropTypes.string,
        features: PropTypes.arrayOf(PropTypes.string),
        engineType: PropTypes.string,
        range: PropTypes.shape(
            {
                value: PropTypes.number,
                unit: PropTypes.string,
            }
        ),
        realRange: PropTypes.shape(
            {
                value: PropTypes.number,
                unit: PropTypes.string,
            }
        ),
        media: PropTypes.arrayOf(PropTypes.string),
        attachments: PropTypes.arrayOf(PropTypes.string),
        rentable: PropTypes.bool,
        idleTime: PropTypes.number,
        licencePlate: PropTypes.string,
        color: PropTypes.string,
        damages: PropTypes.arrayOf(
            PropTypes.shape(
                {
                    title: PropTypes.string,
                    description: PropTypes.string,
                    media: PropTypes.arrayOf(
                        PropTypes.string,
                    ),
                }
            )
        ),
    }),
};

export default CarBooking;
