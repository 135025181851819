import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PinDropIcon from '@material-ui/icons/PinDrop';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
    },
    spacingRight: {
        marginRight: '0.5rem',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

function Address(props) {
    const classes = useStyles();
    const openNavMaps = (location) => {
        const geoCoords = `${location.latitude},${location.longitude}`;

        if (window.cordova.platformId === 'ios') {
            window.open(`maps://?q=${geoCoords}`, '_system');
        } else {
            window.open(`geo:0,0?q=${geoCoords}`, '_system');
        }
    };

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Adresse
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    <Typography component="span" display="block">
                        {props.address.street}
                    </Typography>
                    <Typography
                        component="span"
                        className={classes.spacingRight}
                        display="inline"
                    >
                        {props.address.postalCode}
                    </Typography>
                    <Typography component="span" display="inline">
                        {props.address.city}
                    </Typography>
                    <Typography component="span" display="block">
                        {props.address.country}
                    </Typography>
                </Typography>
            </CardContent>
            {props.location && window.cordova !== undefined && (
                <CardActions disableSpacing>
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        aria-label="navigate to address"
                        className={classes.button}
                        startIcon={<PinDropIcon />}
                        onClick={() => openNavMaps(props.location)}
                        onTouchEnd={() => openNavMaps(props.location)}
                    >
                        Navigation öffnen
                    </Button>
                </CardActions>
            )}
        </Card>
    );
}

Address.propTypes = {
    address: PropTypes.shape({
        street: PropTypes.string.isRequired,
        streetNumber: PropTypes.string.isRequired,
        postalCode: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        country: PropTypes.string,
        addressType: PropTypes.oneOf(['default', 'company', 'invoice']),
    }).isRequired,
    location: PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired,
    }),
};

export default Address;
