import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 275,
    },
    hourDisplayWrapper: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
    },
}));

function OpeningHours(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Öffnungszeiten
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                >
                    {props.openingHours.map(item =>
                        (
                            <Typography
                                component="p"
                                key={item.weekday}
                                className={classes.hourDisplayWrapper}
                            >
                                <Typography component="span">
                                    {item.weekday}
                                </Typography>
                                <Typography component="span">
                                    {item.openingTime} - {item.closingTime}
                                </Typography>
                            </Typography>
                        )
                    )}
                </Typography>
            </CardContent>
        </Card>
    );
}

OpeningHours.propTypes = {
    openingHours: PropTypes.arrayOf(
        PropTypes.shape({
            weekday: PropTypes.string,
            openingTime: PropTypes.string,
            closingTime: PropTypes.string,
        })
    ),
};

export default OpeningHours;
