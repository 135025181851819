import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core/';

import { selectCarById } from '../../app/carSlice';

import CarInformation from './CarInformation';
import CarBooking from './CarBooking';
// import DamageList from '../../Components/DamageList';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    fullWidthTab: {
        flexGrow: 1,
    },
    slide: {
        minHeight: 'calc(100vh - 104px)',
        overflow: 'auto',
    },
    swipeableContainer: {
        height: 'calc(100% - 48px)',
    },
    contentWrapper: {
        height: 'calc(100vh - 56px)',
        width: '100vw',
        [theme.breakpoints.up('sm')]: {
            minHeight: 'calc(100vh - 64px)',
        },
    },
}));

function Car() {
    const { carId } = useParams();
    const carDetails = useSelector(state =>
        selectCarById(state, parseInt(carId, 10))
    );
    const classes = useStyles();
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newTabValue) => {
        setTab(newTabValue);
    };

    const handleTabChangeIndex = index => {
        setTab(index);
    };

    return (
        <div className={classes.contentWrapper}>
            <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
            >
                <Tab
                    wrapped
                    className={classes.fullWidthTab}
                    label="Informationen"
                />
                <Tab
                    wrapped
                    className={classes.fullWidthTab}
                    label="Auto Buchen"
                />
                {/*
                  ToDo: Activate Car Damage Component again when API Call is implemented
                <Tab
                    wrapped
                    className={classes.fullWidthTab}
                    label="Schäden"
                />
                */}
            </Tabs>
            <SwipeableViews
                index={tab}
                onChangeIndex={handleTabChangeIndex}
                className={classes.swipeableContainer}
                animateHeight
            >
                <div className={classes.slide}>
                    <CarInformation car={carDetails} />
                </div>
                <div className={classes.slide}>
                    <CarBooking car={carDetails} />
                </div>
                {/*
                    ToDo: Activate Car Damage Component again when API Call is implemented
                    <div className={classes.slide}>
                    <DamageList carId={parseInt(carId, 10)} damages={carDetails.damages} />
                    </div>
                */}
            </SwipeableViews>
        </div>
    );
}

export default Car;
