import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import Grid from '@material-ui/core/Grid';
import { END_DATE, START_DATE } from 'react-dates/constants';
import * as momentJs from 'moment';
import CustomizableCalendarDay, {
    defaultStyles,
    blockedCalendarStyles,
    highlightedCalendarStyles
} from 'react-dates/lib/components/CustomizableCalendarDay';
import { FormControl, MenuItem, Select, InputLabel } from '@material-ui/core';
import { extendMoment } from 'moment-range';
import { getBlockedDates } from '../../api/carBooking';

const moment = extendMoment(momentJs);

/* ToDo: Activate again when we have the checkAvailability Api Endpoint
    const fetchIsDateAvailable = () => {
        setIsCheckingAvailability(true);
        checkCarDateAvailability({
            carId: props.car.id,
            bookingDate,
        })
            .then(response => {
                if (response.isAvailable) {
                    setIsBookable(response.isAvailable);
                } else {
                    enqueueSnackbar({
                        message: 'Dieses Auto ist an Ihrem Wunschtermin nicht mehr verfügbar.',
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'warning',
                        },
                    });
                    setBookingDate({
                        startDate: null,
                        endDate: null,
                    });
                }
                setIsCheckingAvailability(false);
            })
            .catch(() => {
                setIsCheckingAvailability(false);
            });
    };
    */

const DateTimeRangePicker = ({
    carId,
    classes,
    bookingDateProp,
    setBookingDateProp,
    setIsBookable,
    setIsLoading,
}) => {
    const minimumNights = 0;
    const maximumNights = 2;

    const [bookingDate, setBookingDate] = useState(bookingDateProp);
    const [blockedDateRanges, setBlockedDateRanges] = useState([]);
    const [highlightedDateRanges, setHighlightedDateRanges] = useState([]);
    const [openingHours, setOpeningHours] = useState([]);
    const [isCarRentable, setIsCarRentable] = useState(true);
    const [allowedStartTimes, setAllowedStartTimes] = useState([]);
    const [allowedEndTimes, setAllowedEndTimes] = useState([]);
    const [times, setTimes] = useState({
        startTime: bookingDate?.startDate?.hour(),
        endTime: bookingDate?.endDate?.hour(),
    });

    const [calendarFocused, setCalendarFocused] = useState({
        focusedInput: null,
    });

    const onDatesChange = ({ startDate, endDate }) => {
        if (moment(endDate)
            .diff(startDate, 'd') > maximumNights) {
            setBookingDate({ startDate, endDate: startDate });
            return;
        }
        setBookingDate({ startDate, endDate });
        if (calendarFocused.focusedInput === END_DATE) {
            setCalendarFocused({ focusedInput: START_DATE });
        }
    };

    const isBlocked = date => !isCarRentable || blockedDateRanges.find(blockedDate => blockedDate.isSame(date, 'day'));

    const isHighlighted = date =>
        highlightedDateRanges.find(highlightedDate => highlightedDate.isSame(date, 'day'));

    const isOutsideRange = day => {
        if (
            calendarFocused.focusedInput === END_DATE &&
            !day.isBetween(
                bookingDate.startDate,
                bookingDate.startDate.clone()
                    .add(maximumNights, 'days'),
                'd',
                '[]',
            )
        ) {
            return true;
        }
        if (!openingHours.find(({ weekdayNumber }) => weekdayNumber === day.isoWeekday())) {
            return true;
        }
        return !!(calendarFocused.focusedInput === START_DATE && day.isBefore(moment()));
    };

    const fetchBlockedDates = (currentMonth = null) => {
        const month = currentMonth === null ? moment() : currentMonth;
        getBlockedDates(
            carId,
            month.startOf('month')
                .unix(),
            month.add(1, 'month')
                .endOf('month')
                .unix(),
        )
            .then(response => {
                const bookedRanges = response.blocked.map(tmp =>
                    moment(tmp)
                        .startOf('day'),
                );
                const highlightedRanges = response.highlighted.map(tmp =>
                    moment(tmp)
                        .startOf('day'),
                );
                setBlockedDateRanges(bookedRanges);
                setHighlightedDateRanges(highlightedRanges);
                setOpeningHours(response.location.openingHours);
                setIsCarRentable(response.rentable);
            });
    };

    useEffect(() => {
        setIsLoading(false);
        fetchBlockedDates();
    }, []);
    useEffect(() => {
        if (times.startTime && times.endTime && bookingDate.startDate && bookingDate.endDate) {
            const newDate = {
                startDate: bookingDate.startDate.clone()
                    .hour(times.startTime),
                endDate: bookingDate.endDate.clone()
                    .hour(times.endTime),
            };
            setBookingDate(newDate);
        }
    }, [times]);

    useEffect(() => {
        const selectedOpeningHour = openingHours.find(
            openingHourItem =>
                openingHourItem.weekdayNumber === bookingDate.startDate?.isoWeekday(),
        );
        const selectedClosingHour = openingHours.find(
            openingHourItem => openingHourItem.weekdayNumber === bookingDate.endDate?.isoWeekday(),
        );
        if (selectedOpeningHour) {
            const length = selectedOpeningHour.closingTimeNumber -
                selectedOpeningHour.openingTimeNumber;
            setAllowedStartTimes(Array.from({
                length: length + 1,
            }, (_, i) => i + selectedOpeningHour.openingTimeNumber));
        }
        if (selectedClosingHour) {
            const length = selectedClosingHour.closingTimeNumber -
                selectedClosingHour.openingTimeNumber;
            setAllowedEndTimes(Array.from({
                length: length + 1,
            }, (_, i) => i + selectedClosingHour.openingTimeNumber));
        }
        if (bookingDate.startDate !== null && bookingDate.endDate !== null) {
            /* ToDo: Activate again when we have the checkAvailability Api Endpoint
            * fetchIsDateAvailable();
            */
            setIsBookable(true);
            setBookingDateProp(bookingDate);
        } else {
            setIsBookable(false);
        }
    }, [bookingDate]);
    const customDayStyles = {
        defaultStyles: {
            ...defaultStyles,
            background: 'rgba(0, 253, 28, 0.16)',
        },
        blockedCalendarStyles: {
            ...blockedCalendarStyles,
            background: 'rgba(255, 0, 0, 0.73)',
            color: 'rgba(255, 255, 255, 1.0)',
        },
        highlightedCalendarStyles: {
            ...highlightedCalendarStyles,
            background: 'rgba(255,228,160,0.73)',
        },
    };

    return (
        <Grid item xs className={classes.fullWidth}>
            <DateRangePicker
                startDatePlaceholderText="Startdatum"
                endDatePlaceholderText="Enddatum"
                startDate={bookingDate.startDate}
                startDateId="bookingStartDate"
                endDate={bookingDate.endDate}
                endDateId="bookingEndDate"
                onDatesChange={onDatesChange}
                focusedInput={calendarFocused.focusedInput}
                showClearDates
                reopenPickerOnClearDates
                onFocusChange={focusedInput =>
                    setCalendarFocused({ focusedInput })}
                renderCalendarDay={(props) => (
                    <CustomizableCalendarDay
                        {...props}
                        {...customDayStyles}
                    />
                )}
                onNextMonthClick={fetchBlockedDates}
                onPrevMonthClick={fetchBlockedDates}
                orientation="vertical"
                firstDayOfWeek={1}
                withFullScreenPortal
                block
                hideKeyboardShortcutsPanel
                minDate={moment()}
                minimumNights={minimumNights}
                displayFormat="DD-MM-YYYY"
                isOutsideRange={isOutsideRange}
                isDayBlocked={isBlocked}
                isDayHighlighted={isHighlighted}
                readOnly
            />
            <Grid container columnSpacing={1}>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="ec-datepicker-select-start">Start-Uhrzeit</InputLabel>
                        <Select
                            labelId="ec-datepicker-select-end"
                            autoWidth
                            value={times.startTime}
                            onChange={(value) => setTimes({
                                startTime: value.target.value,
                                endTime: times.endTime,
                            })}
                        >
                            {allowedStartTimes.map((value) => (
                                <MenuItem
                                    key={`select-day-start${value}`}
                                    value={value}
                                >
                                    {value}:00
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="ec-datepicker-select-end">End-Uhrzeit</InputLabel>
                        <Select
                            labelId="ec-datepicker-select-end"
                            autoWidth
                            value={times.endTime}
                            onChange={(value) => setTimes({
                                startTime: times.startTime,
                                endTime: value.target.value,
                            })}
                        >
                            {allowedEndTimes.map((value) => (
                                <MenuItem
                                    key={`select-day-end${value}`}
                                    value={value}
                                >
                                    {value}:00
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
};

DateTimeRangePicker.displayName = 'DateTimeRangePicker';
DateTimeRangePicker.propTypes = {
    carId: PropTypes.number,
    classes: PropTypes.objectOf(PropTypes.any),
    bookingDateProp: PropTypes.shape({
        startDate: PropTypes.objectOf(PropTypes.any),
        endDate: PropTypes.objectOf(PropTypes.any),
    }),
    setIsBookable: PropTypes.func,
    setIsLoading: PropTypes.func,
    setBookingDateProp: PropTypes.func,
};

export default DateTimeRangePicker;
