import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core/';

import { fetchLocations, selectAllLocations } from '../../app/locationSlice';
import { fetchCars } from '../../app/carSlice';
import { enqueueSnackbar } from '../../app/notificationSlice';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    flexList: {
        display: 'flex',
        flexDirection: 'column',
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentWrapper: {
        // the first 56px is the AppBar and the second is the "Choose a Location"-Text
        height: 'calc(100vh - (56px + 56px))',
        width: '100vw',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - (64px + 56px))',
        },
    },
}));

const center = {
    lat: 50.805996776,
    lng: 8.76916359,
};

const mapBoundaries = {
    north: 51,
    south: 50.7,
    west: 8.68,
    east: 8.85,
};

const gMapStyle = {
    width: '100%',
    height: '100%',
};

const options = {
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
    scaleControl: false,
    fullscreenControl: false,
    draggable: true,
    gestureHandling: 'auto',
    minZoom: 12,
    restriction: {
        latLngBounds: mapBoundaries,
        strictBounds: false,
    },
};

function CarMap() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const locations = useSelector(selectAllLocations);
    const locationStatus = useSelector(state => state.locations.status);
    const userStatus = useSelector(state => state.user.loading);
    const carStatus = useSelector(state => state.cars.status);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.GOOGLEMAPS_API_KEY,
        language: 'de_DE',
    });

    useEffect(() => {
        if (locationStatus === 'idle' && carStatus === 'idle' && userStatus === 'idle') {
            const fetchData = async () => {
                await dispatch(fetchLocations());
                await dispatch(fetchCars());
            };
            fetchData();
        }
    }, [locationStatus, dispatch]);

    // ToDO: Implement Error and Success frontend Display

    if (loadError) {
        return <div>GoogleMaps scheint gerade ein Problem zu haben</div>;
    }

    if (locationStatus === 'failed' || carStatus === 'failed') {
        dispatch(enqueueSnackbar({
            message: 'Fehler beim Laden der Daten!',
            options: {
                key: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
                variant: 'error',
            },
        }));

        // eslint-disable-next-line
        console.error('App needed a reload as token was invalid and the axios request was faster than setting the local storage.');
        window.location.reload();
        return <></>;
    }

    return isLoaded &&
        locationStatus === 'succeeded' &&
        carStatus === 'succeeded' ? (
            <div className={classes.flexList}>
                <Typography align="center" style={{ margin: '16px 0' }}>Wähle einen Standort aus!</Typography>
                <div className={classes.contentWrapper}>
                    <GoogleMap
                        key="map"
                        id="script-loader"
                        mapContainerStyle={gMapStyle}
                        zoom={2}
                        center={center}
                        options={options}
                    >
                        {locations.map(location => (
                            <Marker
                                key={`map-location${location.id}`}
                                position={
                                    {
                                        lat: location.location.latitude,
                                        lng: location.location.longitude,
                                    }
                                }
                                clickable
                                onClick={() => history.push({
                                    pathname: `/location/${location.id}`,
                                    state: { appBarTitle: location.name },
                                })}
                                onTouchend={() => history.push({
                                    pathname: `/location/${location.id}`,
                                    state: { appBarTitle: location.name },
                                })}
                            />
                        ))}
                    </GoogleMap>
                </div>
            </div>
        ) : (
            <div className={[classes.centerContent, classes.contentWrapper].join(' ')}>
                <CircularProgress size={70} />
            </div>
        );
}

export default React.memo(CarMap);
