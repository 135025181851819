import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

function AppToolbar(props) {
    const user = useSelector(state => state.user);
    const theme = useTheme();
    const useStyles = makeStyles({
        root: {
            flexGrow: 1,
        },
        flex: {
            flex: 1,
        },
        drawerPaper: {
            position: 'relative',
        },
        menuButton: {
            marginLeft: -12,
            marginRight: 20,
        },
        toolbarMargin: theme.mixins.toolbar,
        aboveDrawer: {
            zIndex: theme.zIndex.drawer + 1,
        },
    });
    const styles = useStyles();
    if (user.token === null) {
        return null;
    }
    return (
        <Fragment>
            <AppBar className={styles.aboveDrawer}>
                <Toolbar>
                    <IconButton
                        className={styles.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={props.onMenuClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={styles.flex}
                    >
                        {props.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={styles.toolbarMargin} />
        </Fragment>
    );
}

AppToolbar.propTypes = {
    title: PropTypes.string.isRequired,
    onMenuClick: PropTypes.func.isRequired,
};

export default AppToolbar;
