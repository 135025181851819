import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Button from '@material-ui/core/Button';

import 'react-dates/initialize';

import Booking from '../../Components/Booking';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        borderRadius: 0,
        height: 'calc(100vh - 104px)',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
    },
    flexGrow: {
        flexGrow: 1,
    },
    fullWidth: {
        width: '100%',
    },
    customCtaButton: {
        padding: '16px 16px',
        fontSize: '1.375rem',
    },
    marginY: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    successAvatar: {
        color: theme.palette.success.main,
        backgroundColor: '#fff',
        width: '140px',
        height: '140px',
        borderWidth: '4px',
        borderStyle: 'solid',
        borderColor: theme.palette.success.main,
    },
    successButton: {
        borderColor: theme.palette.success.main,
        color: theme.palette.success.main,
    },
}));

function CarBookingSuccess(props) {
    const classes = useStyles();
    if (props.customHeight) {
        classes.root = {
            minWidth: 275,
            borderRadius: 0,
            height: props.customHeight,
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'flex-start',
        };
    }
    const history = useHistory();

    return (
        <Fragment>
            <Paper elevation={0} className={classes.root}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                    className={classes.marginY}
                >
                    <Grid item xs>
                        <Avatar className={classes.successAvatar}>
                            <DoneAllIcon
                                style={{ fontSize: 100 }}
                            />
                        </Avatar>
                    </Grid>
                    <Grid item xs>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="h2"
                        >
                            Buchung {props.booking.status}
                        </Typography>
                    </Grid>
                    {props.booking.status === 'abgeschlossen' ? '' : (
                        <Grid item xs>
                            {props.customFinishButton !== null ? props.customFinishButton :
                                (
                                    <Button
                                        variant="outlined"
                                        onClick={() => history.push({
                                            pathname: '/',
                                            state: { appBarTitle: 'Standorte' },
                                        })}
                                        disabled={props.booking.status === 'abgeschlossen'}
                                        className={classes.successButton}
                                    >
                                        Abschließen
                                    </Button>
                                )}
                        </Grid>
                    )}
                </Grid>
                <Booking booking={props.booking} disabled={props.booking.status === 'abgeschlossen'} />
            </Paper>
        </Fragment>
    );
}

CarBookingSuccess.propTypes = {
    customFinishButton: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    customHeight: PropTypes.string,
    booking: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
        car: PropTypes.shape({
            id: PropTypes.number,
            location: PropTypes.shape(
                {
                    id: PropTypes.number,
                    name: PropTypes.string,
                    location: PropTypes.shape(
                        {
                            latitude: PropTypes.number,
                            longitude: PropTypes.number,
                        }
                    ),
                    termsOfService: PropTypes.string,
                }
            ),
            manufacturer: PropTypes.shape(
                {
                    name: PropTypes.string,
                    logo: PropTypes.string,
                }
            ),
            model: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            yearOfProduction: PropTypes.number,
            countryOfProduction: PropTypes.string,
            features: PropTypes.arrayOf(PropTypes.string),
            engineType: PropTypes.string,
            range: PropTypes.shape(
                {
                    value: PropTypes.number,
                    unit: PropTypes.string,
                }
            ),
            realRange: PropTypes.shape(
                {
                    value: PropTypes.number,
                    unit: PropTypes.string,
                }
            ),
            media: PropTypes.arrayOf(PropTypes.string),
            attachments: PropTypes.arrayOf(PropTypes.string),
            rentable: PropTypes.bool,
            idleTime: PropTypes.number,
            licencePlate: PropTypes.string,
            color: PropTypes.string,
            damages: PropTypes.arrayOf(
                PropTypes.shape(
                    {
                        title: PropTypes.string,
                        description: PropTypes.string,
                        media: PropTypes.arrayOf(
                            PropTypes.string,
                        ),
                    }
                )
            ),
        }),
        pickupLocation: PropTypes.number,
        returnLocation: PropTypes.number,
        customer: PropTypes.number,
        bookingFrom: PropTypes.number,
        bookingTo: PropTypes.number,
        invoice: PropTypes.string,
        attachments: PropTypes.arrayOf(
            PropTypes.string
        ),
    }).isRequired,
};

export default CarBookingSuccess;
