import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#1d9f90',
            main: '#049484',
            dark: '#048577',
            contrastText: '#fff',
        },
    },
});

export default theme;
