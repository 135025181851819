/* eslint no-param-reassign: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api/api';

const initialState = {
    data: [],
    status: 'idle',
    error: null,
};

/*
* Thunks
* Bug inside the @reduxjs/toolkit package throws an error if you try
* to use the payload creator without the first argument.
* This should have been fixed in 1.3.5 => https://github.com/reduxjs/redux-toolkit/pull/502
* ToDo: Try and Debug again or open a new Issue on Github
*/
export const fetchCars = createAsyncThunk(
    'cars/fetchCars',
    // eslint-disable-next-line default-param-last
    async (arg = null, { getState }) => { // eslint-disable-line no-unused-vars
        const { status } = getState().cars;
        if (status !== 'pending') {
            return false;
        }
        const response = await api.get('/api/v1/cars/');
        return response.data;
    }
);

const slice = createSlice({
    name: 'cars',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchCars.pending]: state => {
            if (state.status === 'idle') {
                state.status = 'pending';
            }
        },
        [fetchCars.fulfilled]: (state, action) => {
            if (state.status === 'pending') {
                state.status = 'succeeded';
                state.data = action.payload.data;
            }
        },
        [fetchCars.rejected]: (state, action) => {
            if (state.status === 'pending') {
                state.status = 'failed';
                state.error = action.error;
            }
        },
    },
});

export default slice.reducer;

export const selectAllCars = state => state.cars.data;

export const selectCarsByLocationId = (state, locationId) =>
    state.cars.data.filter(car => car.location && car.location.id === locationId);

export const selectCarById = (state, carId) =>
    state.cars.data.find(car => car.id === carId);
