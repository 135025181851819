import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core/';

const useStyles = makeStyles(theme => ({
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentWrapper: {
        height: 'calc(100vh - 56px)',
        width: '100vw',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 64px)',
        },
    },
    contentWrapperWithSecond: {
        height: 'calc(100vh - 104px)',
        width: '100vw',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 112px)',
        },
    },
}));

function Loader(props) {
    const classes = useStyles();

    return props.hasSecondaryMenu ? (
        <div
            className={[classes.centerContent, classes.contentWrapperWithSecond].join(' ')}
        >
            <CircularProgress size={70} />
        </div>
    ) : (
        <div
            className={[classes.centerContent, classes.contentWrapper].join(' ')}
        >
            <CircularProgress size={70} />
        </div>
    );

}

Loader.propTypes = {
    hasSecondaryMenu: PropTypes.bool,
};

export default Loader;
