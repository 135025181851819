import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

function ProtectedRoute({ component: Component, ...rest }) {
    const user = useSelector(state => state.user);
    return (
        <Route
            {...rest}
            render={() => (user.token !== null ? <Component /> : <Redirect to="/login" />)}
        />
    );
}

ProtectedRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
};

export default ProtectedRoute;
