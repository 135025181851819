import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Address from './Address';
// import PersonResponsible from './PersonResponsible';
import OpeningHours from './OpeningHours';

function LocationInformation(props) {
    return (
        <Fragment>
            {
                props.location >= 1 &&
                <Address address={props.location.address} location={props.location.location} />
            }
            {/*
            <PersonResponsible
                name={props.location.personResponsible}
                tel={props.location.phoneNumber}
            />
            */}
            <OpeningHours openingHours={props.location.openingHours} />
        </Fragment>
    );
}

LocationInformation.propTypes = {
    location: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        phoneNumber: PropTypes.string,
        isActive: PropTypes.bool,
        location: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
        }).isRequired,
        address: PropTypes.shape({
            street: PropTypes.string.isRequired,
            streetNumber: PropTypes.string.isRequired,
            postalCode: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
            country: PropTypes.string,
            addressType: PropTypes.oneOf(['default', 'company', 'invoice']),
        }),
        openingHours: PropTypes.arrayOf(
            PropTypes.shape({
                weekday: PropTypes.string,
                openingTime: PropTypes.string,
                closingTime: PropTypes.string,
            }),
        ),
        media: PropTypes.arrayOf(
            PropTypes.string,
        ),
        attachments: PropTypes.arrayOf(
            PropTypes.string,
        ),
    }),
};

export default LocationInformation;
