import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

function CompactLocation(props) {
    const classes = useStyles();
    const history = useHistory();

    const openNavMaps = (location) => {
        const geoCoords = `${location.latitude},${location.longitude}`;

        if (window.cordova.platformId === 'ios') {
            window.open(`maps://?q=${geoCoords}`, '_system');
        } else {
            window.open(`geo:0,0?q=${geoCoords}`, '_system');
        }
    };

    return (
        <List className={classes.root}>
            <ListItem
                button
                onClick={() => history.push({
                    pathname: `/location/${props.location.id}`,
                    state: { appBarTitle: props.location.name },
                })}
            >
                <ListItemText
                    primary={props.location.name}
                    secondary="Name"
                />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary={props.location.phoneNumber}
                    secondary="Telefonnummer"
                />
            </ListItem>
            <ListItem
                button
                onClick={() => openNavMaps(props.location.location)}
            >
                <ListItemText
                    primary={`${props.location.address.street} ${props.location.address.streetNumber}, ${props.location.address.city}`}
                    secondary="Adresse"
                />
            </ListItem>
        </List>
    );
}

CompactLocation.propTypes = {
    location: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        phoneNumber: PropTypes.string,
        isActive: PropTypes.bool,
        location: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
        }).isRequired,
        address: PropTypes.oneOfType(
            PropTypes.shape({
                street: PropTypes.string.isRequired,
                streetNumber: PropTypes.string.isRequired,
                postalCode: PropTypes.string.isRequired,
                city: PropTypes.string.isRequired,
                country: PropTypes.string,
                addressType: PropTypes.oneOf(['default', 'company', 'invoice']),
            }),
            PropTypes.array,
        ),
        openingHours: PropTypes.arrayOf(
            PropTypes.shape({
                weekday: PropTypes.string,
                openingTime: PropTypes.string,
                closingTime: PropTypes.string,
            }),
        ),
        media: PropTypes.arrayOf(
            PropTypes.string,
        ),
        attachments: PropTypes.arrayOf(
            PropTypes.string,
        ),
    }),
};

export default CompactLocation;
