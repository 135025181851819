import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import * as momentJs from 'moment';
import Moment from 'react-moment';

import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Button from '@material-ui/core/Button';

import { selectCarById } from '../../app/carSlice';
import { selectLocationById } from '../../app/locationSlice';

import Carinformation from '../../screens/Car/CarInformation';
import CompactLocation from './CompactLocation';
import { updateCarBooking } from '../../api/carBooking';
import DateTimeRangePicker from './DateTimeRangePicker';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    paddingLeft: {
        paddingLeft: theme.spacing(1),
    },
    paddingRight: {
        paddingRight: theme.spacing(1),
    },
    block: {
        display: 'block',
    },
    successButton: {
        borderColor: theme.palette.success.main,
        color: theme.palette.success.main,
    },
}));

const moment = momentJs;

function Booking(props) {
    moment.locale('de');
    const classes = useStyles();
    /** @type {boolean} changeDateEnabled */
    const [changeDateEnabled, setChangeDateEnabled] = useState(false);
    const [bookingDate, setBookingDate] = useState({
        startDate: moment.unix(props.booking.bookingFrom),
        endDate: moment.unix(props.booking.bookingTo),
    });

    const bookingCar = useSelector(state =>
        selectCarById(state, props.booking.car.id)
    );
    const bookingPickupLocation = useSelector(state =>
        selectLocationById(state, props.booking.pickupLocation)
    );
    const bookingReturnLocation = useSelector(state =>
        selectLocationById(state, props.booking.returnLocation)
    );

    return (
        <Fragment>
            <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
            >
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h3"
                            color="textPrimary"
                            align="center"
                        >
                            {props.booking.id}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            align="center"
                            className={classes.block}
                        >
                            Buchungsnummer
                        </Typography>
                    </Grid>
                </Grid>
                { changeDateEnabled && !props.disabled ? (
                    <Fragment>
                        <DateTimeRangePicker
                            carId={props.booking.car.id}
                            bookingDateProp={bookingDate}
                            setBookingDateProp={setBookingDate}
                            classes={classes}
                            setIsBookable={() => {}}
                            setIsLoading={() => {}}
                        />
                        <Button
                            variant="outlined"
                            onClick={() => {
                                updateCarBooking(
                                    {
                                        bookingId: props.booking.id,
                                        bookingTo: bookingDate.endDate.unix(),
                                        bookingFrom: bookingDate.startDate.unix(),
                                    }
                                );
                                setChangeDateEnabled(false);
                            }}
                            className={classes.successButton}
                        >
                            Datum aktualisieren
                        </Button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Grid item xs={5} className={classes.paddingLeft}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    color="textPrimary"
                                    align="left"
                                    onClick={() => setChangeDateEnabled(true)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Moment format="DD-MM-YYYY HH:00">
                                        {bookingDate.startDate}
                                    </Moment>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    align="left"
                                    className={classes.block}
                                >
                                    Start der Buchung
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography align="center">
                                <ArrowRightAltIcon fontSize="large" color="action" />
                            </Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.paddingRight}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    color="textPrimary"
                                    align="right"
                                    onClick={() => setChangeDateEnabled(true)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Moment format="DD-MM-YYYY HH:00">
                                        {bookingDate.endDate}
                                    </Moment>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    align="right"
                                    className={classes.block}
                                >
                                    Ende der Buchung
                                </Typography>
                            </Grid>
                        </Grid>
                    </Fragment>
                ) }
            </Grid>
            <Accordion square>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-car-content"
                    id="panel-car-header"
                >
                    <Typography className={classes.heading}>Auto</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Carinformation car={bookingCar} />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-pickup-content"
                    id="panel-pickup-header"
                >
                    <Typography className={classes.heading}>Abholstandort</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CompactLocation location={bookingPickupLocation} />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-return-content"
                    id="panel-return-header"
                >
                    <Typography className={classes.heading}>Rückgabe Standort</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CompactLocation location={bookingReturnLocation} />
                </AccordionDetails>
            </Accordion>
        </Fragment>
    );
}

Booking.propTypes = {
    disabled: PropTypes.bool,
    booking: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
        car: PropTypes.number,
        pickupLocation: PropTypes.number,
        returnLocation: PropTypes.number,
        customer: PropTypes.number,
        bookingFrom: PropTypes.number,
        bookingTo: PropTypes.number,
        invoice: PropTypes.string,
        attachments: PropTypes.arrayOf(
            PropTypes.string
        ),
    }).isRequired,
};

export default Booking;
