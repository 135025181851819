import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core/';

import { selectCarsByLocationId } from '../../app/carSlice';
import { selectLocationById } from '../../app/locationSlice';

import LocationCarList from './LocationCarList';
import LocationInformation from './LocationInformation';
import LocationDetails from './LocationDetails';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    fullWidthTab: {
        flexGrow: 1,
    },
    slide: {
        minHeight: 100,
        overflow: 'auto',
    },
    swipeableContainer: {
        height: 'calc(100% - 56px)',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100% - 64px)',
        },
    },
    contentWrapper: {
        height: 'calc(100vh - 56px)',
        width: '100vw',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 64px)',
        },
    },
}));

function Location() {
    const { locationId } = useParams();
    const locationDetails = useSelector(state =>
        selectLocationById(state, parseInt(locationId, 10))
    );
    const locationCars = useSelector(state =>
        selectCarsByLocationId(state, parseInt(locationId, 10))
    );
    const classes = useStyles();
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newTabValue) => {
        setTab(newTabValue);
    };

    const handleTabChangeIndex = index => {
        setTab(index);
    };

    return (
        <div className={classes.contentWrapper}>
            <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
            >
                <Tab wrapped className={classes.fullWidthTab} label="Fahrzeugliste" />
                <Tab wrapped className={classes.fullWidthTab} label="Informationen" />
                <Tab wrapped className={classes.fullWidthTab} label="Anfahrt" />
            </Tabs>
            <SwipeableViews
                index={tab}
                onChangeIndex={handleTabChangeIndex}
                className={classes.swipeableContainer}
            >
                <div className={classes.slide}>
                    <LocationCarList cars={locationCars} />
                </div>
                <div className={classes.slide}>
                    <LocationInformation location={locationDetails} />
                </div>
                <div className={classes.slide}>
                    <LocationDetails location={locationDetails} />
                </div>
            </SwipeableViews>
        </div>
    );
}

export default Location;
