import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card, Checkbox,
    Collapse, FormControl, FormControlLabel, FormGroup, FormLabel, Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, Paper, TextField,
    Typography
} from '@material-ui/core';
import Moment from 'react-moment';
import CardContent from '@material-ui/core/CardContent';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { extendMoment } from 'moment-range';
import Button from '@material-ui/core/Button';
import * as momentJs from 'moment';
import { getOwnBookings, finishCarBooking } from '../../api/carBooking';
import CarBookingSuccess from './CarBookingSuccess';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        borderRadius: 0,
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-end',
    },
    marginY: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    flexGrow: {
        flexGrow: 1,
    },
    fullWidth: {
        width: '100%',
    },
    customCtaButton: {
        padding: '16px 16px',
        fontSize: '1.375rem',
    },
    successButton: {
        borderColor: theme.palette.success.main,
        color: theme.palette.success.main,
    },
}));

const moment = extendMoment(momentJs);

const BookingList = () => {
    moment.locale('de');
    const classes = useStyles();
    const [bookings, setBookings] = useState([]);
    const [openedBooking, setOpenedBooking] = useState(0);
    const [returnCarModal, setReturnCarModal] = useState(false);
    const defaultState = {
        fueling: true,
        accidentFree: true,
        cleaning: true,
        returningApproved: true,
        fuelingReason: '',
        accidentFreeReason: '',
        cleaningReason: '',
        returningApprovedReason: '',
    };
    const [returningState, setReturningState] = useState(Object.assign({}, defaultState));

    useEffect(() => {
        getOwnBookings()
            .then((result) => {
                setBookings(result);
            });
    }, []);

    const resetModal = () => {
        setReturnCarModal(false);
        setReturningState(Object.assign({}, defaultState));
    };

    const returnCar = () => {
        finishCarBooking({
            bookingId: openedBooking,
            returnCarBookingObject: returningState,
        })
            .then(() => {
                resetModal();
                getOwnBookings()
                    .then((result) => {
                        setBookings(result);
                    });
            });
    };

    const handleChange = (event) => {
        setReturningState({ ...returningState, [event.target.name]: event.target.checked });
    };

    return (
        <Card className={classes.root}>
            <CardContent className={classes.flexGrow}>
                <List key="carList">
                    {bookings.map((booking) => (
                        <ListItem
                            key={booking.id}
                            alignItems="flex-start"
                            divider
                            onClick={() => setOpenedBooking(booking.id)}
                        >
                            <ListItemIcon>
                                {openedBooking === booking.id ?
                                    <ExpandMore /> :
                                    <ChevronRightIcon />}
                            </ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={(
                                    <React.Fragment>
                                        <Typography
                                            noWrap
                                            component="p"
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            Buchung Nr. {booking.id}
                                        </Typography>
                                        <Typography
                                            noWrap
                                            component="h6"
                                            variant="h6"
                                            color="textPrimary"
                                        >
                                            {booking.car.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            <Moment format="DD-MM-YYYY HH:00">
                                                {booking.bookingFrom}
                                            </Moment>{' '}
                                            bis{' '}
                                            <Moment format="DD-MM-YYYY HH:00">
                                                {booking.bookingTo}
                                            </Moment>
                                        </Typography>
                                    </React.Fragment>
                                )}
                                secondary={(
                                    <Collapse in={booking.id === openedBooking} timeout="auto">
                                        {returnCarModal ? (
                                            <Paper elevation={0} className={classes.root}>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    spacing={0}
                                                    className={classes.marginY}
                                                >
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">
                                                            Fahrzeugzustand bei Abgabe
                                                        </FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={(
                                                                    <Checkbox
                                                                        // eslint-disable-next-line
                                                                        checked={returningState.fueling}
                                                                        onChange={handleChange}
                                                                        name="fueling"
                                                                    />
                                                                )}
                                                                label="Tanken"
                                                            />
                                                            {returningState.fueling === false ? (
                                                                <TextField
                                                                    label="Begründung"
                                                                    multiline
                                                                    maxRows={4}
                                                                    // eslint-disable-next-line
                                                                    value={returningState.fuelingReason}
                                                                    name="fuelingReason"
                                                                    onChange={handleChange}
                                                                />
                                                            ) : ''}
                                                            <FormControlLabel
                                                                control={(
                                                                    <Checkbox
                                                                        // eslint-disable-next-line
                                                                        checked={returningState.accidentFree}
                                                                        onChange={handleChange}
                                                                        name="accidentFree"
                                                                    />
                                                                )}
                                                                label="Unfallfrei"
                                                            />
                                                            {
                                                                // eslint-disable-next-line
                                                                returningState.accidentFree === false ? (
                                                                    <TextField
                                                                        label="Begründung"
                                                                        multiline
                                                                        maxRows={4}
                                                                        value={
                                                                        // eslint-disable-next-line
                                                                        returningState.accidentFreeReason
                                                                        }
                                                                        name="accidentFreeReason"
                                                                        onChange={handleChange}
                                                                    />
                                                                ) : ''
                                                            }
                                                            <FormControlLabel
                                                                control={(
                                                                    <Checkbox
                                                                    // eslint-disable-next-line
                                                                    checked={returningState.cleaning}
                                                                        onChange={handleChange}
                                                                        name="cleaning"
                                                                    />
                                                                )}
                                                                label="Reinigung"
                                                            />
                                                            {returningState.cleaning === false ? (
                                                                <TextField
                                                                    label="Begründung"
                                                                    multiline
                                                                    maxRows={4}
                                                                    // eslint-disable-next-line
                                                                    value={returningState.cleaningReason}
                                                                    name="cleaningReason"
                                                                    onChange={handleChange}
                                                                />
                                                            ) : ''}
                                                            <FormControlLabel
                                                                control={(
                                                                    <Checkbox
                                                                    // eslint-disable-next-line
                                                                    checked={returningState.returningApproved}
                                                                        onChange={handleChange}
                                                                        name="returningApproved"
                                                                    />
                                                                )}
                                                                label="Rückgabe bestätigt"
                                                            />
                                                            {
                                                                // eslint-disable-next-line
                                                                returningState.returningApproved === false ? (
                                                                    <TextField
                                                                        label="Begründung"
                                                                        multiline
                                                                        maxRows={4}
                                                                        value={
                                                                            returningState.returningApprovedReason // eslint-disable-line
                                                                        }
                                                                        name="returningApprovedReason"
                                                                        onChange={handleChange}
                                                                    />
                                                                ) : ''
                                                            }
                                                        </FormGroup>
                                                    </FormControl>
                                                    <br />
                                                    <br />
                                                    <div>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => returnCar()}
                                                            className={classes.successButton}
                                                        >
                                                            Vorgang Abschließen
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => resetModal()}
                                                            className={classes.successButton}
                                                        >
                                                            Vorgang Abbrechen
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Paper>
                                        ) : (
                                            <CarBookingSuccess
                                                booking={{
                                                    ...booking,
                                                    bookingFrom: moment(booking.bookingFrom)
                                                        .unix(),
                                                    bookingTo: moment(booking.bookingTo)
                                                        .unix(),
                                                }}
                                                customHeight="auto"
                                                customFinishButton={(
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => setReturnCarModal(true)}
                                                        className={classes.successButton}
                                                    >
                                                        Fahrzeug zurückgeben
                                                    </Button>
                                                )}
                                            />
                                        )}
                                    </Collapse>
                                )}
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

BookingList.displayName = 'BookingList';

export default BookingList;
