import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Typography, Chip } from '@material-ui/core';
import EvStationIcon from '@material-ui/icons/EvStation';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(theme => ({
    chipContainer: {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

function LocationCarList(props) {
    const classes = useStyles();
    return (
        <List key="carList">
            {props.cars.map(car => (
                <ListItem
                    key={car.id}
                    alignItems="flex-start"
                    divider
                    button
                    component={Link}
                    to={{
                        pathname: `/car/${car.id}`,
                        state: { appBarTitle: car.name },
                    }}
                >
                    <ListItemIcon>
                        <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={(
                            <React.Fragment>
                                <Typography
                                    noWrap
                                    component="h6"
                                    variant="h6"
                                    color="textPrimary"
                                >
                                    {car.name}
                                </Typography>
                                <Typography
                                    gutterBottom
                                    noWrap
                                    component="p"
                                    variant="body1"
                                    color="textSecondary"
                                >
                                    {car.manufacturer.name}
                                </Typography>
                            </React.Fragment>
                        )}
                        secondary={(
                            <div className={classes.chipContainer}>
                                <Chip
                                    size="small"
                                    icon={
                                        car.engineType === 'Elektrisch' ? (
                                            <EvStationIcon />
                                        ) : (
                                            <LocalGasStationIcon />
                                        )
                                    }
                                    label={`Treibstoff: ${car.engineType}`}
                                    color="primary"
                                    key="engineType"
                                />
                                {
                                    car.features.map(feature => (
                                        <Chip
                                            size="small"
                                            label={`${feature}`}
                                            key={`${feature}`}
                                        />
                                    ))
                                }
                            </div>
                        )}
                    />
                </ListItem>
            ))}
        </List>
    );
}

LocationCarList.propTypes = {
    cars: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            location: PropTypes.shape(
                {
                    id: PropTypes.number,
                    name: PropTypes.string,
                    location: PropTypes.shape(
                        {
                            latitude: PropTypes.number,
                            longitude: PropTypes.number,
                        }
                    ),
                }
            ),
            manufacturer: PropTypes.shape(
                {
                    name: PropTypes.string,
                    logo: PropTypes.string,
                }
            ),
            model: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            yearOfProduction: PropTypes.number,
            countryOfProduction: PropTypes.string,
            features: PropTypes.arrayOf(PropTypes.string),
            engineType: PropTypes.string,
            range: PropTypes.shape(
                {
                    value: PropTypes.number,
                    unit: PropTypes.string,
                }
            ),
            realRange: PropTypes.shape(
                {
                    value: PropTypes.number,
                    unit: PropTypes.string,
                }
            ),
            media: PropTypes.arrayOf(PropTypes.string),
            attachments: PropTypes.arrayOf(PropTypes.string),
            rentable: PropTypes.bool,
            idleTime: PropTypes.number,
            licencePlate: PropTypes.string,
            color: PropTypes.string,
            damages: PropTypes.arrayOf(
                PropTypes.shape(
                    {
                        title: PropTypes.string,
                        description: PropTypes.string,
                        media: PropTypes.arrayOf(
                            PropTypes.string,
                        ),
                    }
                )
            ),
        })
    ).isRequired,
};

export default LocationCarList;
