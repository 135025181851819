import api from './api';

/**
 * @typedef {Object} StaticContentItem
 * @property {string} title
 * @property {string} headline
 * @property {string} body
 */

/**
 * @typedef {Object} StaticContent
 * @property {Object.<string, StaticContentItem>}
 */

/**
 * @returns {Promise<StaticContent>}
 */
export const getStaticData = async () => {
    try {
        const { data } = await api.get('/api/v1/static-content');
        return data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw new Error('There was a problem with updating your booking');
    }
};

export default { getStaticData };
