import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppToolbar from './AppToolbar';
import AppMenuDrawer from './AppMenuDrawer';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    flex: {
        flex: 1,
    },
    drawerPaper: {
        position: 'relative',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    toolbarMargin: theme.mixins.toolbar,
    aboveDrawer: {
        zIndex: theme.zIndex.drawer + 1,
    },
});

function AppBar() {
    const location = useLocation();
    const history = useHistory();
    const token = useSelector(state => state.user.token);
    const [drawer, setDrawer] = useState(false);
    const [title, setTitle] = useState('Karte');

    const toggleDrawer = () => {
        setDrawer(!drawer);
    };

    const onItemClick = ToolbarTitle => () => {
        setTitle(ToolbarTitle);
        setDrawer(!drawer);
    };

    useEffect(() => {
        if (typeof location.state !== 'undefined' && typeof location.state.appBarTitle === 'string') {
            setTitle(location.state.appBarTitle);
        }
    }, [location]);

    useEffect(() => {
        if (token !== null) {
            history.push('/');
        }
    }, [token]);

    return (
        <Fragment>
            <AppToolbar title={title} onMenuClick={toggleDrawer} />
            <AppMenuDrawer
                open={drawer}
                onClose={toggleDrawer}
                onItemClick={onItemClick}
                variant="temporary"
                classes={styles}
            />
        </Fragment>
    );
}

export default withStyles(styles)(AppBar);
