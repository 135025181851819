import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { getStaticData } from '../../api/staticData';

const StaticPage = () => {
    const { state } = useLocation();
    const { pageTitle } = useParams();
    const [pageData, setPageData] = useState({ headline: '', title: '', body: '' });
    useEffect(() => {
        if (typeof state === 'undefined') {
            // in case the state of current location has changed
            // due to unforeseeable behaviour, then we re-fetch it
            getStaticData().then(data => {
                Object.entries(data).forEach(([key, pageContent]) => {
                    if (key === pageTitle) {
                        setPageData(pageContent);
                    }
                });
            });
            return;
        }
        setPageData(state.pageData);
    }, [state, pageTitle]);
    return (
        <Container maxWidth="sm">
            <h2>{pageData.headline}</h2>
            <div dangerouslySetInnerHTML={{ __html: pageData.body }} />
        </Container>
    );
};

StaticPage.displayName = 'StaticPage';

export default StaticPage;
