import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Carousel from 'react-material-ui-carousel';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import CarInformationGridItem from './CarInformationGridItem';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        borderRadius: 0,
        marginTop: '10px',
    },
    media: {
        height: 240,
    },
});

function Carinformation(props) {
    const classes = useStyles();
    if (!props.car) {
        return <></>;
    }

    return (
        <Fragment>
            <Card className={classes.root}>
                <Carousel animation="slide" swipe="false" interval="8000">
                    {props.car.media.map((image, i) => (
                        <CardMedia
                            className={classes.media}
                            key={i}
                            image={image}
                        />
                    ))}
                </Carousel>
                <CardActionArea>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Datenblatt
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                        >
                            <Grid container spacing={0}>
                                <CarInformationGridItem
                                    label="Hersteller:"
                                    value={props.car.manufacturer?.name}
                                />
                                <CarInformationGridItem
                                    label="Modell:"
                                    value={props.car.model}
                                />
                                <CarInformationGridItem
                                    label="Farbe:"
                                    value={props.car.color}
                                />
                                <CarInformationGridItem
                                    label="Kraftstoff:"
                                    value={props.car.engineType}
                                />
                                <CarInformationGridItem
                                    label="Hersteller Reichweite:"
                                    value={props.car.range?.value}
                                />
                                <CarInformationGridItem
                                    label="Reelle Reichweite:"
                                    value={props.car.realRange?.value}
                                />
                                <CarInformationGridItem
                                    label="Nummernschild:"
                                    value={props.car.licencePlate}
                                />
                                <CarInformationGridItem
                                    label="Anzahl an Sitzen:"
                                    value={props.car.numberOfSeats}
                                />
                                <CarInformationGridItem
                                    label="Anhängerkupplung vorhanden:"
                                    value={props.car.towCoupling ? 'Ja' : 'Nein'}
                                />
                                <CarInformationGridItem
                                    label="Navigationssystem vorhanden:"
                                    value={props.car.navigationSystem ? 'Ja' : 'Nein'}
                                />
                                <CarInformationGridItem
                                    label="Zubehör:"
                                    value={props.car.additionalGear}
                                />
                                <CarInformationGridItem
                                    label="Beschreibung Ladevorgang:"
                                    value={props.car.descriptionCharging}
                                />
                                <CarInformationGridItem
                                    label="Laden oder Tanken:"
                                    value={props.car.chargeOrFuel ? 'Laden' : 'Tanken'}
                                />
                                <CarInformationGridItem
                                    label="Zuladung:"
                                    value={props.car.vehicleLoadCapacity}
                                />
                                <CarInformationGridItem
                                    label="Vorschäden:"
                                    value={props.car.preExistingDefect}
                                />
                                <CarInformationGridItem
                                    label="Versicherungsinformationen:"
                                    value={props.car.insuranceDetails}
                                />
                            </Grid>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            {/* props.car.details.range.infotext && (
                <Card className={classes.root}>
                    <CardActionArea>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                            >
                                Reichweiten Information
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {props.car.details.range.infotext}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            ) */}
        </Fragment>
    );
}

Carinformation.propTypes = {
    car: PropTypes.shape({
        id: PropTypes.number,
        location: PropTypes.shape(
            {
                id: PropTypes.number,
                name: PropTypes.string,
                location: PropTypes.shape(
                    {
                        latitude: PropTypes.number,
                        longitude: PropTypes.number,
                    }
                ),
            }
        ),
        manufacturer: PropTypes.shape(
            {
                name: PropTypes.string,
                logo: PropTypes.string,
            }
        ),
        model: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        yearOfProduction: PropTypes.number,
        countryOfProduction: PropTypes.string,
        features: PropTypes.arrayOf(PropTypes.string),
        engineType: PropTypes.string,
        range: PropTypes.shape(
            {
                value: PropTypes.number,
                unit: PropTypes.string,
            }
        ),
        realRange: PropTypes.shape(
            {
                value: PropTypes.number,
                unit: PropTypes.string,
            }
        ),
        media: PropTypes.arrayOf(PropTypes.string),
        attachments: PropTypes.arrayOf(PropTypes.string),
        rentable: PropTypes.bool,
        idleTime: PropTypes.number,
        licencePlate: PropTypes.string,
        color: PropTypes.string,
        damages: PropTypes.arrayOf(
            PropTypes.shape(
                {
                    title: PropTypes.string,
                    description: PropTypes.string,
                    media: PropTypes.arrayOf(
                        PropTypes.string,
                    ),
                }
            )
        ),
        numberOfSeats: PropTypes.number,
        towCoupling: PropTypes.bool,
        navigationSystem: PropTypes.bool,
        additionalGear: PropTypes.string,
        descriptionCharging: PropTypes.string,
        chargeOrFuel: PropTypes.bool,
        vehicleLoadCapacity: PropTypes.string,
        preExistingDefect: PropTypes.string,
        insuranceDetails: PropTypes.string,
    }),
};

export default Carinformation;
