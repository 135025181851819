/* eslint no-param-reassign: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api/api';

const initialState = {
    data: [],
    status: 'idle',
    error: null,
};

/*
 * Thunks
 * Bug inside the @reduxjs/toolkit package throws an error if you try
 * to use the payload creator without the first argument.
 * This should have been fixed in 1.3.5 => https://github.com/reduxjs/redux-toolkit/pull/502
 * ToDo: Try and Debug again or open a new Issue on Github
 */
export const fetchLocations = createAsyncThunk(
    'locations/fetchLocations',
    // eslint-disable-next-line default-param-last
    async (args = null, { getState }) => { // eslint-disable-line no-unused-vars
        const { status } = getState().locations;
        if (status !== 'pending') {
            return false;
        }
        const response = await api.get('/api/v1/locations/');
        return response.data;
    }
);

const slice = createSlice({
    name: 'locations',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchLocations.pending]: state => {
            if (state.status === 'idle') {
                state.status = 'pending';
            }
        },
        [fetchLocations.fulfilled]: (state, action) => {
            if (state.status === 'pending') {
                state.status = 'succeeded';
                state.data = action.payload.data;
            }
        },
        [fetchLocations.rejected]: (state, action) => {
            if (state.status === 'pending') {
                state.status = 'failed';
                state.error = action.error;
            }
        },
    },
});

export default slice.reducer;

export const selectAllLocations = state => state.locations.data;

export const selectLocationById = (state, locationId) =>
    state.locations.data.find(location => location.id === locationId);
