// /* global cordova:false */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import Collapse from '@material-ui/core/Collapse';

import App from './app/App';
import configureAppStore from './app/store';

import theme from './app/defaultTheme';
import './styles/main.scss';

const render = () => {
    ReactDOM.render(
        <Provider store={configureAppStore()}>
            <Router>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        TransitionComponent={Collapse}
                    >
                        <App />
                    </SnackbarProvider>
                </ThemeProvider>
            </Router>
        </Provider>,
        document.getElementById('root')
    );
};

if (!window.cordova) {
    render();
} else {
    document.addEventListener('deviceready', render, false);
}

if (process.env.NODE_ENV === 'development' && import.meta.hot) {
    import.meta.hot.accept(App, render);
}
