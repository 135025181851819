import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Carousel from 'react-material-ui-carousel';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
    },
    spacingRight: {
        marginRight: '0.5rem',
    },
    button: {
        margin: theme.spacing(1),
    },
    media: {
        height: 200,
    },
}));

function LocationDetails(props) {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Carousel animation="slide" swipe="false" interval="8000">
                {props.location.media.map((image, i) => (
                    <CardMedia
                        className={classes.media}
                        key={i}
                        image={image}
                    />
                ))}
            </Carousel>
            <CardContent>
                <Typography variant="h5" component="h2">
                    {props.location.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {props.location.description}
                </Typography>
            </CardContent>
        </Card>
    );
}

LocationDetails.propTypes = {
    location: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        phoneNumber: PropTypes.string,
        isActive: PropTypes.bool,
        location: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
        }).isRequired,
        address: PropTypes.shape({
            street: PropTypes.string.isRequired,
            streetNumber: PropTypes.string.isRequired,
            postalCode: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
            country: PropTypes.string,
            addressType: PropTypes.oneOf(['default', 'company', 'invoice']),
        }),
        openingHours: PropTypes.arrayOf(
            PropTypes.shape({
                weekday: PropTypes.string,
                openingTime: PropTypes.string,
                closingTime: PropTypes.string,
            }),
        ),
        media: PropTypes.arrayOf(
            PropTypes.string,
        ),
        attachments: PropTypes.arrayOf(
            PropTypes.string,
        ),
    }),
};

export default LocationDetails;
