import React, { Fragment, useState, useEffect } from 'react';
import { Route, Link, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CloseIcon from '@material-ui/icons/Close';
import MapIcon from '@material-ui/icons/Map';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { logout } from '../userSlice';

import ProtectedRoute from './ProtectedRoute';

import CarMap from '../../screens/CarMap';
import Login from '../../screens/Login';
import ForgotPassword from '../../screens/ForgotPassword';
import Location from '../../screens/Location';
import Car from '../../screens/Car';

import { getStaticData } from '../../api/staticData';
import StaticPage from '../../screens/StaticPage';
import BookingList from '../../screens/Car/BookingList';

const useStyles = makeStyles({
    drawerPaper: {
        position: 'relative',
        width: '100%',
    },
});

function AppMenuDrawer(props) {
    const token = useSelector(state => state.user.token);
    const dispatch = useDispatch();
    const styles = useStyles();
    const logoutHandler = () => {
        dispatch(logout());
        props.onClose();
    };

    /**
     * @typedef {import('../../api/staticData.js').StaticContent} StaticContent
     * @type {StaticContent} staticData
     */
    const [staticData, setStaticData] = useState({});

    useEffect(() => {
        if (token !== null) {
            getStaticData().then(data => setStaticData(data));
        }
    }, [token]);

    return (
        <Fragment>
            <Drawer
                variant={props.variant}
                open={props.open}
                onClose={props.onClose}
                classes={{
                    paper: styles.drawerPaper,
                }}
            >
                <List component="nav" aria-label="main navigation">
                    <ListItem button onClick={props.onClose}>
                        <ListItemIcon>
                            <CloseIcon />
                        </ListItemIcon>
                        <ListItemText>Menu schließen</ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem
                        button
                        component={Link}
                        to="/"
                        onClick={props.onItemClick('Karte')}
                    >
                        <ListItemIcon>
                            <MapIcon />
                        </ListItemIcon>
                        <ListItemText>Karte</ListItemText>
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/booking-list"
                        onClick={props.onItemClick('Buchungsübersicht')}
                    >
                        <ListItemIcon>
                            <KeyboardArrowRightIcon />
                        </ListItemIcon>
                        <ListItemText>Buchungsübersicht</ListItemText>
                    </ListItem>
                </List>
                <Divider />
                <List component="nav" aria-label="main static-pages">
                    { Object.entries(staticData).map(([key, item]) => (
                        <ListItem
                            button
                            component={Link}
                            to={{
                                pathname: `/page/${key}`,
                                state: { appBarTitle: item.title, pageData: item },
                            }}
                            onClick={props.onItemClick(item.title)}
                        >
                            <ListItemIcon>
                                <KeyboardArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    )) }
                </List>
                <Divider />
                <List component="nav" aria-label="main logout">
                    <ListItem button to="/login" onClick={logoutHandler}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </Drawer>
            <main>
                <Switch>
                    <ProtectedRoute exact path="/" component={CarMap} />
                    <Route path="/login" component={Login} />
                    <Route path="/forgotPassword" component={ForgotPassword} />
                    <ProtectedRoute path="/location/:locationId" component={Location} />
                    <ProtectedRoute path="/car/:carId" component={Car} />
                    <ProtectedRoute path="/booking-list/" component={BookingList} />
                    <ProtectedRoute path="/page/:pageTitle" component={StaticPage} />
                    <ProtectedRoute component={CarMap} />
                </Switch>
            </main>
        </Fragment>
    );
}

AppMenuDrawer.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onItemClick: PropTypes.func,
    variant: PropTypes.string,
};

export default AppMenuDrawer;
